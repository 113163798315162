<template>
  <dash-page class="wsRounded" no-padding-main main-background="white" >

    <template #default>
      <!-- Header -->
      <div class="d-flex align-center justify-space-between px-8 py-3 pb-0 wsRounded"  >
        <div style="width: 100%">
          <!-- Task Name-->

          <div class="d-flex align-center">
            <div v-if="entity.courses">
              <v-btn v-if="entity.courses.length > 0"
                     :to="businessDashLink('courses/' + entity.courses[0].uuid + '/editor') "
                     outlined  icon class="mr-3" :color="wsDARKLIGHT">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

            </div>

            <div  style="width: 100%">
              <wsEditorSingleLine v-model="entity.name[selectedLang]"
                                  :placeholder="$t('EnterTaskName')"
                                  output-format="text"

                                  @loaded="titleReady = true"
                                  :editorStyle="lectureNameStyle" />
              <h5 class="font-weight-medium ml-2">{{ $t('TaskEditor') }}</h5>
            </div>

          </div>

        </div>
        <!-- Language Switch-->
        <div>
          <v-chip @click=" checkLectureNameTranslation(lang); selectedLang = lang;"  v-for="(lang) in lang" :key="lang"
                  class="mr-1 mt-2"
                  :outlined="selectedLang !== lang"
                  small >
            <!--      :color="text[lang] ? wsDARK : 'grey'" dark-->
            <h4>{{ lang }} </h4>
          </v-chip>
          <v-menu offset-y transition="slide-y-transition">

            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                     class="noCaps mt-2 px-0"
                     :color="wsDARK"
                     text  small >
          <span>
            <v-icon small>mdi-plus</v-icon>
            {{ $t('AddTranslation') }} </span>
              </v-btn>

            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item @click="addTranslation(lang)" v-for="lang in remainedLanguages" :key="lang">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ lang }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-sheet  class=" ma-6 pa-6 wsRoundedHalf">

        <wsEditorRegular @loaded="editorReady = true"
                         @save="saveTask"
                         v-model="entity.description[selectedLang]" />
        <v-skeleton-loader  type="text@2" class="px-4 mt-3" v-if="!editorReady"  />
        <v-fade-transition mode="out-in">
          <v-sheet  v-if="!editorReady"
                    class="wsRoundedHalf d-flex align-center justify-center mx-4 mt-3"
                    color="grey lighten-2"
                    height="700"
                    style="cursor: progress" >
            <v-progress-circular color="white" indeterminate size="76"/>
          </v-sheet>
        </v-fade-transition>

      </v-sheet>

      <!-- Upload File Dialog -->
      <ws-dialog  :title="$t('UploadFile')"
                  :save-text="$t('Yes')"
                  :show-save="false"
                  :cancel-text="$t('Finish')"
                  v-model="displayUploadFile">

        <wsImageUpload class="mt-6" @upload="uploadFile" :custom-text="true" >
          <h5 class="text-center font-weight-light"> {{$t('UploadFileInFormats')}} :  </h5>
          <h5 class="text-center mt-3"> .pdf, .doc, docx, .xls, .xlsx, .ppt, .pptx, .pages, .numbers, .keynote, .jpg, .jpeg, .png </h5>
        </wsImageUpload>
      </ws-dialog>

    </template>

    <!-- SIDE MENU-->
    <template #side>
      <div class="d-flex justify-space-between align-center">
        <h4>{{ $t('TaskEditor') }}</h4>
        <v-btn @click="saveTask"
               :loading="loading"
               :disabled="loading"
               class="noCaps"
               small outlined >{{ $t('Save') }}</v-btn>
      </div>


      <!--  task type-->
      <div class="d-flex justify-space-between align-center mt-9">

        <h5 class="font-weight-light">{{ $t('TaskType') }}:</h5>
        <ft-select v-model="entity.type" @input="saveTask" :items="TASK_TYPES" :disabled="entity.type === 'video' " >
          <template v-slot="{text}">
            <div class="d-flex">
              <v-icon small class="mr-1">{{ TASK_TYPE_ICON(entity.type) }}</v-icon>
              <h5 v-if="entity.type !== 'video'">{{ text ? text : $t('Choose') }}</h5>
              <h5 v-else>{{  $t('VideoMeeting') }}</h5>

            </div>
          </template>
        </ft-select>
      </div>

      <!-- Test Properties-->
      <v-expand-transition>
        <div v-if="entity.type === 'test'" class=" mt-4">
          <div class="d-flex justify-space-between align-center">
            <h5 class="font-weight-light">{{ $t('Test') }}:</h5>
            <v-btn small outlined class="noCaps" @click="addEditTest">{{ entity.test_id ? $t('EditTest') :  $t('AddTest')}} </v-btn>
          </div>
        </div>
      </v-expand-transition>
      <!-- VideoMeeting Properties-->

      <!-- Student Answer Properties-->
      <v-expand-transition>
        <div v-if="entity.type !== 'test'"
             class="d-flex justify-space-between align-center">
          <h5 class="font-weight-light">{{ $t('StudentFileAnswer') }}:</h5>
          <v-switch dense v-model="entity.student_upload"   />
        </div>
      </v-expand-transition>

      <!-- Files -->
      <h5 class="mt-6">{{ $t('Files') }} :</h5>
      <v-simple-table class="wsHoverDarker" style="background: transparent" dense>
        <tbody>
        <tr style="background: transparent" class="pointer" v-for="(file,i) in entity.files "  :key="i + 'file'">
          <td width="10px"><v-icon small>mdi-file</v-icon></td>
          <td @click="openFile(file)" class="pl-0">
            <h5 class="font-weight-light">{{ file.name }}</h5>
          </td>
          <td width="10px">
            <v-btn @click="deleteFile( file.uuid, i )" small icon><v-icon small>mdi-delete-outline</v-icon></v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

      <v-btn text class="noCaps" @click="showUploadFile">
        <v-icon small class="mr-2">mdi-plus</v-icon>
        {{ $t('AddFile')}}
      </v-btn>

      <h5 class="mt-6">{{ $t('Course_') }} </h5>
      <v-simple-table class="wsHoverDarker" :style="`background-color: transparent`" dense>
        <tbody>
        <tr style="background: transparent" v-for=" (item,i) in entity.courses "  :key="i + 'program'">
          <td width="10px"><v-icon small>mdi-file-document-multiple-outline</v-icon></td>
          <td style="cursor: pointer" @click="$router.push(businessDashLink('courses/' + item.uuid + '/editor') )" class="pl-0">
            <h5 class="font-weight-light">{{ item.name }}</h5>
          </td>
        </tr>
        </tbody>
      </v-simple-table>


    </template>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import wsEditorRegular from "@/components/UI/wsEditorRegular";
import wsEditorSingleLine from "@/components/UI/wsEditorSingleLine";
import wsImageUpload from "@/components/UI/wsImageUpload";

export default {
  name: "tasksEditor",
  props : ['uuid'],
  components : {
    wsEditorRegular,
    wsEditorSingleLine,
    wsImageUpload
  },
  data() {
    return {
      titleReady : false,
      topicReady : false,
      editorReady : false,
      loading : false,
      lectureNameStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 24px;\n' +
          '  font-weight: 600;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
      lectureTopicStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 18px;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
      test : null,

      selectedTopic : 0,
      entity : {
        name : {},
        description : {},
        files : [],
      },

      // dialogs
      displayDeleteTopic : false,
      displayUploadFile  : false,
      //lang
      selectedLang : 'ua',
      langBase : []

    }
  },
  computed: {

    langDefault() {
      return [process.env.VUE_APP_I18N_LOCALE, process.env.VUE_APP_I18N_FALLBACK_LOCALE]
    },
    lang() {
      return [...this.langDefault, ...this.langBase ]
    },
    remainedLanguages(){
      let items = this.PARAMS_languages
      items = items.filter( el=> !this.lang.includes(el) )
      return items
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_TASK_EDIT',
        'SAVE_TASK',
        'REMOVE_TASK_FILE',
        'NEW_TASK_TEST'
    ]),
    ...mapActions('upload', [
      'UPLOAD_PRIVATE_FILE',
      'GET_PRIVATE_FILE'
    ]),


    saveTask() {
      this.notify(this.$t('ChangesSaved'))
      this.loading = true;
      this.SAVE_TASK(this.entity).then((out)=>{
        if ( out.data ) {
          this.entity = out.data.task
        } else {
          this.notify(this.$t('NetworkError'))
        }
        this.loading = false
      }).catch(()=>{
        this.notify(this.$t('NetworkError'))
        this.loading = false })
    },
    addEditTest() {

      this.loading = true

      if ( this.entity.test_id ) {

        this.$router.push(this.businessDashLink('tests/edit/' + this.entity.test_id))

      } else {

        this.NEW_TASK_TEST(this.uuid).then((out)=>{
          if ( out.result ) {
            this.$router.push(this.businessDashLink('tests/edit/' + out.data.test.uuid))
          }
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })

      }


    },

    //files
    showUploadFile() {
      this.displayUploadFile = true
    },
    uploadFile(file) {

      let data = {
        file : file ,
        params : {
          entity : 'task',
          entity_id : this.uuid,
          create_entity : true,
        }

      }
      this.UPLOAD_PRIVATE_FILE(data).then((out)=>{
        if ( out.result ) {
          this.notify(this.$t('FileUploaded'))
          this.entity.files.push(out.data.url)
          this.displayUploadFile = false
        }
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      }).catch(()=>{
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      })

    },
    async openFile(file) {
      let result = await this.GET_PRIVATE_FILE(file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    deleteFile(uuid, index) {
      this.loading = true
      let data = {
        task: this.uuid,
        file : uuid
      }
      this.REMOVE_TASK_FILE(data).then((out)=>{
        if ( out.result ) {
          this.entity.files.splice(index,1)
          this.notify(this.$t('FileDeleted'))
        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
      })
    },

    //technical
    addTranslation(lang) {
      this.checkLectureNameTranslation(lang)
      this.langBase.push(lang)
    },
    getTranslation(translations) {
      return translations[this.selectedLang] ? translations[this.selectedLang] :  this.$t('NoTranslation')
    },
    checkLectureNameTranslation(lang) {

      if ( !this.entity.name[lang] ) {
        this.entity.name[lang]='';
        this.entity = JSON.parse(JSON.stringify(this.entity))
      }
      if ( !this.entity.description[lang] ) {
        this.entity.description[lang]='';
        this.entity = JSON.parse(JSON.stringify(this.entity))
      }

    },

    initPage() {
      this.selectedLang = this.$i18n.locale
      if ( this.uuid ) {
        this.loading = true;
        this.GET_TASK_EDIT(this.uuid).then((out)=>{
          if ( out.data ) {

            this.$store.state.tests.store_selectedTask = out.data.task.uuid
            if ( out.data.task.name ) {
              this.$store.state.tests.store_selectedTaskName = out.data.task.name[this.$i18n.locale]
            }


            if ( !out.data.task.description ) {
              out.data.task.description = {
                [process.env.VUE_APP_I18N_LOCALE] : ""
              }
            }
            this.entity = out.data.task

            if ( this.entity.name && Object.prototype.toString.call(this.entity.name) === '[object Object]')  {
              Object.keys(this.entity.name).forEach((key)=>{
                if (!this.lang.includes(key)) {
                  this.langBase.push(key)
                }
              })
            }

          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(()=>{
          this.notify(this.$t('NetworkError'))
          this.loading = false })
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.singleLineEditor {

}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>